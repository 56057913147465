import React from 'react'
import CabaretLayout from '../../components/cabaretlayout'
// import { cabaretTicketUrl } from '../../data/cabaret-data'

const CabaretBilletterie = () => (
    <CabaretLayout>
        <div className='cabaret-billetterie'>

            <div className='cabaret-page-title'>
                <h1>Billetterie</h1>
            </div>

            <h3>Ouverture de la billetterie le 7 janvier 2025</h3>
            <h3>L’admission est gratuite pour les 10 ans et moins</h3>
            <h3>Si tu es une curieuse ou un curieux, procure-toi la Passe-Curieux qui te donnera accès à une tonne de privilèges, dont l’accès aux quatres soirées de spectacles à un prix avantageux, en plus d’une consommation sur place. Autre privilège très important; seules les personnes détenant une Passe-Curieux auront le droit de vote du quatrième projet pouvant accéder à La Grande Finale! Les billets sont aussi disponibles pour chaque soirée individuellement.</h3>

            {/* BILLETS */}
            <div className="flex flex-wrap w-full mt-8">

                {/* 27 JANVIER */}
                {/* <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                    <div className="bg-white rounded-3xl">
                        <div className="bg-purple-cabaret rounded-t-3xl px-1 py-6 text-white">
                            <h1 className="leading-none">27 janvier</h1>
                        </div>
                        <div className="text-purple-cabaret py-4 px-6">
                            <h3><a href="/cabaret/lescandidats/ouimerci" className='hover:underline'>oui merci</a></h3>
                            <h3><a href="/cabaret/lescandidats/oliverforest" className='hover:underline'>Oliver Forest</a></h3>
                            <h3><a href="/cabaret/lescandidats/anaisconstantin" className='hover:underline'>Anaïs Constantin</a></h3>
                            <h3><a href="/cabaret/lescandidats/sloanlucas" className='hover:underline'>Sloan Lucas</a></h3>
                            <p className='my-4'>-</p>
                            <b><p className='text-[16px]'>Présence de Sara Dufour</p></b>
                            <div className='cabaret-billetterie !m-0 p-0'>
                                <div className='flex justify-center items-center'>
                                    <form action={cabaretTicketUrl} target='_blank'>
                                        <button type='submit'>Acheter</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* 17 FÉVRIER */}
                {/* <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                    <div className="bg-white rounded-3xl">
                        <div className="bg-purple-cabaret rounded-t-3xl px-1 py-6 text-white">
                            <h1 className="leading-none">17 février</h1>
                        </div>
                        <div className="text-purple-cabaret py-4 px-6">
                            <h3><a href="/cabaret/lescandidats/corail" className='hover:underline'>Corail</a></h3>
                            <h3><a href="/cabaret/lescandidats/halde" className='hover:underline'>Halde</a></h3>
                            <h3><a href="/cabaret/lescandidats/bermuda" className='hover:underline'>Bermuda</a></h3>
                            <h3><a href="/cabaret/lescandidats/fovelle" className='hover:underline'>Fovelle</a></h3>
                            <p className='my-4'>-</p>
                            <br/>
                            <div className='cabaret-billetterie !m-0 p-0'>
                                <div className='flex justify-center items-center'>
                                    <form action={cabaretTicketUrl} target='_blank'>
                                        <button type='submit'>Acheter</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* 9 MARS */}
                {/* <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                    <div className="bg-white rounded-3xl">
                        <div className="bg-purple-cabaret rounded-t-3xl px-1 py-6 text-white">
                            <h1 className="leading-none">9 mars</h1>
                        </div>
                        <div className="text-purple-cabaret py-4 px-6">
                            <h3><a href="/cabaret/lescandidats/olivierlessard" className='hover:underline'>Olivier Lessard</a></h3>
                            <h3><a href="/cabaret/lescandidats/madameautruche" className='hover:underline'>Madame Autruche</a></h3>
                            <h3><a href="/cabaret/lescandidats/curepipe" className='hover:underline'>Cure-pipe</a></h3>
                            <h3><a href="/cabaret/lescandidats/cocorose" className='hover:underline'>Coco Rose</a></h3>
                            <p className='my-4'>-</p>
                            <br/>
                            <div className='cabaret-billetterie !m-0 p-0'>
                                <div className='flex justify-center items-center'>
                                    <form action={cabaretTicketUrl} target='_blank'>
                                        <button type='submit'>Acheter</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* La Grande Finale */}
                {/* <div className="md:w-1/2 w-full text-center px-4 mt-8">
                    <div className="bg-white rounded-3xl">
                        <div className="bg-purple-cabaret rounded-t-3xl px-1 py-6 text-white">
                            <h1 className="leading-none">La Grande Finale</h1>
                        </div>
                        <h3>30 mars 2024</h3>
                        <h3>Salle Multi - Le Germain Charlevoix</h3>
                        <h3>Plusieurs prix remis, dont une bourse de 10 000$ de la part de SiriusXM</h3>
                        <div className="text-purple-cabaret px-6">
                            <p className='my-4'>-</p>
                            <div className='cabaret-billetterie !m-0 p-0'>
                                <div className='flex justify-center items-center'>
                                    <form action={cabaretTicketUrl} target='_blank'>
                                        <button type='submit'>Acheter</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* PASSE CURIEUX */}
                {/* <div className="md:w-1/2 w-full text-center px-4 md:my-0 !mt-8">
                    <div className="bg-white rounded-3xl">
                        <div className="bg-purple-cabaret rounded-t-3xl px-1 py-6 text-white">
                            <h1 className="leading-none">Passe-Curieux</h1>
                        </div>
                        <h3>Accès aux quatres soirées à prix avantageux</h3>
                        <h3>Une consommation sur place</h3>
                        <h3>Droit de vote du quatrième projet pouvant accéder à La Grande Finale!</h3>
                        <p className='my-4'>-</p>
                        <div className='cabaret-billetterie !m-0 p-0'>
                            <div className='flex justify-center items-center'>
                                <form action={cabaretTicketUrl} target='_blank'>
                                    <button type='submit'>Acheter</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>

        </div>

    </CabaretLayout>
)

export default CabaretBilletterie
